import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/Layout"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer"
import PageTitle from "../../components/PageTitle"

export class index extends Component {
  render() {
    return (
      <div className="clearfix">
        <Layout />
        <Header />
        <div>
          <PageTitle link="/news" title="M8 News" />
          {/* Content*/}
          <section id="content">
            <div className="content-wrap">
              <div className="container clearfix">
                {/* Posts*/}
                <div
                  className="post-grid grid-container grid-3 clearfix"
                  id="posts"
                  data-layout="fitRows"
                >
                  <div className="entry clearfix">
                    <div className="entry-image">
                      <Link to="/news/smartFarming">
                        <Img
                          fluid={this.props.data.file.childImageSharp.fluid}
                          placeholderClassName="image_fade"
                          alt="Standard Post"
                        />
                      </Link>
                    </div>
                    <div className="entry-title">
                      <h2>
                        <Link to="/news/smartFarming">Smart Farming</Link>
                      </h2>
                    </div>
                    <ul className="entry-meta clearfix">
                      <li>
                        <i className="icon-calendar3" /> 30th July 2019
                      </li>
                    </ul>
                    <div className="entry-content">
                      <p>
                        Smart Farming is a farm management concept using modern
                        technology to increase the quality and quantity of
                        agricultural products.
                      </p>
                      <Link className="more-link" to="/news/smartFarming">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                {/* Posts End*/}
                {/* Content End*/}
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    )
  }
}

export const query = graphql`
  query {
    file(relativePath: { eq: "blog/smart-farming.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default index
